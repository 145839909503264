import React from 'react'
import { useSelector } from 'react-redux';
import style from "../index.module.scss";

export default function TazaPayButton({ pay = () => { } }) {
    const paymentSettings = useSelector(state => state.globalState.pymentSettings);

    return paymentSettings?.taza_pay_enabled && (
        <div className={style['tazapay']} onClick={() => pay("taza_pay")}>Tazapay</div>)
}
