import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";
import TEXTS from "../config/texts";

import { REQUESTS } from "../api/requests";

import classes from "./style/validateMac.module.scss";

export default function ValidateMacAddress({
  setMacIsValid,
  value = "",
  setValue,
  setDevice,
}) {
  const translation = useSelector((state) => state.globalState.translation); //  is used to get the translation data from the global state.

  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const validateMacAddress = () => {

    if (value?.length === 0) {
      return;
    }

    function callback(data) {
      if (setDevice) {
        setDevice(data);
      }

      if (setMacIsValid) setMacIsValid(true);

      setMessage({
        text: translation["Mac address is valid"] || TEXTS["Mac address is valid"],
        type: true,
      });
    }

    function errorCallback(error) {
      if (setMacIsValid) setMacIsValid(false);

      setMessage({
        text: translation["Mac address is not valid"] || TEXTS["Mac address is not valid"],
        type: false,
      });
    }

    REQUESTS.MAC_VALIDATION({ mac: value }, callback, errorCallback);
  };

  const generateMacFormat = (e) => {
    var mac = e.target.value.replace(/[^0-9a-z]/gi, "");
    var result = "";

    for (var i = 0; i < mac.length; i++) {
      if (i % 2 === 0 && i !== 0) {
        result += ":";
      }
      result += mac[i];
    }
    setValue(result.substring(0, 17));
  };

  useEffect(() => {
    let timeoutId

    if (value?.length == 17) validateMacAddress()

    return () => clearTimeout(timeoutId);
  }, [value]);

  return (
    <div style={{ height: '87px' }}>
      <Form.Item
        label={translation["Mac address"] || TEXTS["Mac address"]}
        rules={[
          {
            required: true,
            message:
              translation["This field is required!"] ||
              TEXTS["This field is required!"],
          },
        ]}
      >
        <input
          value={value}
          onChange={generateMacFormat}
          placeholder={translation["Enter device mac address"] || TEXTS["Enter device mac address"]}
          name="mac"
          className={classes["mac-inp"]}
        />
        <div
          className={classes[message.text ? "message-open" : "message-close"]}
        >
          <span
            className={classes[message.type ? "success-text" : "error-text"]}
          >
            {message.text}
          </span>
        </div>
      </Form.Item>
    </div>
  );
}
