import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import { Table } from "antd";

import { REQUESTS } from "../../api/requests";

import TEXTS from "../../config/texts";
import ICONS from "../../config/icons";
import { parseFullDate } from "../../config/formats";
import { getColumnDateProps, getColumnSearchProps, getQueryDate } from "../../config/config";

import Pages from "../../components/Pages";
import MyIcon from "../../components/IconsAntSvg";

import WithdrawFundsDrawer from "../../layout/components/WithdrawFundsDrawer";

// Withdrawals page is used to display all the withdrawals made by the user. The user can also make a new withdrawal
// from this page.

export default function Withdrawals() {

    const appInfo = useSelector((state) => state.globalState.appInfo);
    const translation = useSelector((state) => state.globalState.translation);
    const getWithdrawAgain = useSelector((state) => state.globalState.getWithdrawAgain);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [date, setDate] = useState("");
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(searchParams.get("limit") || 10);
    const [search, setSearch] = useState(searchParams.get("search") || {});
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState(["id", "DESC"]);
    const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
    const [withdrawalsData, setWithdrawalsData] = useState([]);
    const [visibleWithdrawFundsDrawer, setVisibleWithdrawFundsDrawer] = useState(false);

    const searchInput = useRef(null);

    const paymentSettings = useSelector((state) => state.globalState.pymentSettings);

    const columns = [
        {
            title: "#",
            width: 60,
            dataIndex: `id`,
            key: "index",
            align: "center",
            render: (text, record, index) => {
                return limit * (currentPage - 1) + index + 1;
            },
        },

        {
            title: `${translation["Email"] || TEXTS["Email"]}`,
            width: 300,
            align: "center",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps(searchInput),
        },

        {
            title: `${translation["Amount"] || TEXTS["Amount"]}`,
            dataIndex: "amount",
            align: "center",
            key: "amount",
            render: (record, text, index) => {
                return `${record} ${paymentSettings?.currency}`;
            },
            sorter: true,
        },

        {
            title: `${translation["Method"] || TEXTS["Method"]}`,
            dataIndex: "method",
            align: "center",
            key: "method",
            sorter: true,
        },

        {
            title: `${translation["Accepted"] || TEXTS["Accepted"]}`,
            dataIndex: "status",
            align: "center",
            key: "status",
            sorter: true,
            render: (record, text, index) => {
                if (record) {
                    return <MyIcon children={ICONS.CHECK} />;
                } else {
                    return <MyIcon children={ICONS.TIMES} />;
                }
            },
        },

        {
            title: `${translation["Date"] || TEXTS["Date"]}`,
            dataIndex: "createdAt",
            align: "center",
            key: "createdAt",
            sorter: true,
            render: (record, text, index) => {
                return parseFullDate(record);
            },
            ...getColumnDateProps((e) => setDate(e)),
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }

        setSearch(filters);

        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    const getWithdrawalsHistory = () => {
        setLoading(true);

        const query = {
            limit,
            search: {},
            pagination: 1,
            page: currentPage,
            sort: JSON.stringify(sort),
        };

        if (search.email) {
            query.search["email"] = search.email[0];
        }

        query.search = JSON.stringify(query.search);

        const queryDate = getQueryDate(date);

        if (queryDate) query.between = queryDate;

        function callback(data) {
            setLoading(false);
            setTotal(data.count);
            setLimit(data.limit);
            setWithdrawalsData(data.rows);

            if (data.rows.length === 0 && currentPage !== 1) {
                setCurrentPage((current) => current - 1);
            } else {
                setCurrentPage(data.currentPage);
            }
        }

        function errorCallback() {
            setLoading(false);
        }

        navigate({
            search: `${createSearchParams(query)}`,
        });

        REQUESTS.WITHDRAWALS.GET(query, callback, errorCallback);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            getWithdrawalsHistory();
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [sort, date, limit, currentPage, search]);

    useEffect(() => {
        if (getWithdrawAgain === true) {
            getWithdrawalsHistory();
        }
    }, [getWithdrawAgain]);

    return (
        <Pages
            title={translation["Withdrawals history"] || TEXTS["Withdrawals history"]}
            onClick={() => setVisibleWithdrawFundsDrawer(true)}
            actions={translation["Withdraw funds"] || TEXTS["Withdraw funds"]}
        >
            <Table
                loading={loading}
                bordered
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                columns={columns}
                dataSource={withdrawalsData}
                scroll={{
                    x: "max-content",
                }}
                size="small"

            />

            <WithdrawFundsDrawer
                visible={visibleWithdrawFundsDrawer}
                onClose={() => setVisibleWithdrawFundsDrawer(false)}
                removeSearchSorter={() => setSearch({})}
            />
        </Pages>
    );
}
