import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { Table } from "antd";

import { REQUESTS } from "../../../api/requests";

import TEXTS from "../../../config/texts";
import ICONS from "../../../config/icons";
import { getColumnDateProps, getColumnSearchProps, getQueryDate } from '../../../config/config';
import { parseFullDate } from "../../../config/formats";

import Tags from "../../../components/Tags";
import MyIcon from "../../../components/IconsAntSvg";
import Pages from "../../../components/Pages";
import InfoDrawer from "./InfoDrawer";

export default function SubResellerActivationHistory({ current }) {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [activationsHistory, setActivationsHistory] = useState([]);

    const [total, setTotal] = useState(0);

    const [limit, setLimit] = useState(searchParams.get("limit") || 10);

    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [sort, setSort] = useState(["id", "DESC"]);

    const [date, setDate] = useState("");

    const [search, setSearch] = useState(searchParams.get("search") || {});

    const [showInfo, setShowInfo] = useState(null);

    const searchInput = useRef(null);

    const paymentSettings = useSelector((state) => state.globalState.pymentSettings);

    const translation = useSelector((state) => state.globalState.translation);

    const columns = [
        {
            title: "#",
            width: 60,
            dataIndex: `id`,
            align: "center",
            key: "index",
            render: (text, record, index) => {
                return limit * (currentPage - 1) + index + 1;
            },
        },
        {
            title: `${translation["Method"] || TEXTS["Method"]}`,
            dataIndex: "method",
            align: "center",
            ...getColumnSearchProps(searchInput),
            render: (text, record, index) => {
                let paymentInfo;
                try {
                    paymentInfo = JSON.parse(record.payment_info);
                } catch (error) { }
                const method = text === "to sub reseller" || text === "back to balance" || text === "activation added" || text === "activation taken"
                return <span className={method && (paymentInfo?.reseller_id || paymentInfo?.sub_reseller_id) ? "method-link" : ""}>{text}</span>
            }
        },
        {
            title: `${translation["Transaction id"] || TEXTS["Transaction id"]}`,
            dataIndex: "transaction_id",
            align: "center",
            key: "transaction_id",
            render: (record, text, index) => {
                return record || <Tags />;
            },
            ...getColumnSearchProps(searchInput),
        },
        {
            title: `${translation["Count"] || TEXTS["Count"]}`,
            dataIndex: "count",
            align: "center",
            key: "count",
            sorter: true,
        },
        {
            title: `${translation["Status"] || TEXTS["Status"]}`,
            dataIndex: "status",
            align: "center",
            key: "status",
            sorter: true,
            render: (record, text, index) => {
                if (record) {
                    return <MyIcon children={ICONS.CHECK} />;
                } else {
                    return <MyIcon children={ICONS.TIMES} />;
                }
            },
        },
        {
            title: `${translation["Price"] || TEXTS["Price"]}`,
            dataIndex: `price`,
            align: "center",
            key: "price",
            render: (record, text, index) => {
                return `${record} ${paymentSettings?.currency}`;
            },
            sorter: true,
        },
        {
            title: `${translation["Date"] || TEXTS["Date"]}`,
            dataIndex: "createdAt",
            align: "center",
            key: "createdAt",
            sorter: true,
            render: (record, text, index) => {
                return parseFullDate(record);
            },
            ...getColumnDateProps(setDate),
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }

        setSearch(filters);

        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };


    const getActivationsHistory = () => {

        if (!current?.id) return;

        setLoading(true);

        // query is the query for the activations history. It is used for the pagination, sorting, searching, and filtering.
        const query = {
            limit,
            search: {},
            pagination: 1,
            page: currentPage,
            sort: JSON.stringify(sort),
            reseller_id: current.id,
        }

        if (search.transaction_id) {
            query.search["transaction_id"] = search.transaction_id[0];
        }
        if (search.method) {
            query.search["method"] = search.method[0];
        }

        if (query.search) {
            query.search = JSON.stringify(query.search);
        }

        const queryDate = getQueryDate(date);

        if (queryDate) query.between = queryDate;

        function callback(data) {
            setLoading(false);
            setTotal(data.count);
            setLimit(data.limit);
            setCurrentPage(data.currentPage);
            setActivationsHistory(data.rows);

            if (data.rows.length === 0 && currentPage !== 1) {
                setCurrentPage((current) => current - 1);
            } else {
                setCurrentPage(data.currentPage);
            }
        }

        function errorCallback() {
            setLoading(false);
        }

        navigate({
            search: `${createSearchParams(query)}`,
        });

        REQUESTS.ACTIVATIONS_HISTORY.GET(query, callback, errorCallback);
    };

    const getResellerInfo = (obj, type) => {
        if (!obj[type]) return;

        const query = {
            filter: JSON.stringify({
                id: obj[type]
            })
        }

        const callback = (response) => {
            setShowInfo(response.rows?.[0] || null);
        }

        REQUESTS.SUBRESELLERS.GET(query, callback)
    }

    const tableRowOnClick = (record) => {
        try {
            if (record.method === "to sub reseller" || record.method === "back to balance" || record.method === "activation added" || record.method === "activation taken") {
                let paymentInfo;

                let type = record?.method === "to sub reseller" || record?.method === "back to balance" ? "sub_reseller_id" : record?.method === "activation added" || record?.method === "activation taken" ? "reseller_id" : null;

                paymentInfo = JSON.parse(record.payment_info);

                getResellerInfo(paymentInfo, type)
            }
        } catch (error) { }
    }

    useEffect(() => {
        const timeout = setTimeout(getActivationsHistory, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [sort, date, limit, currentPage, search]);

    return (
        <Pages title={translation['Credits history'] || TEXTS['Credits history']}>
            <Table
                rowKey="id"
                bordered
                loading={loading}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                columns={columns}
                dataSource={activationsHistory}
                scroll={{
                    x: "max-content",
                }}
                size="small"
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => tableRowOnClick(record)
                    }
                }}
            />
            <InfoDrawer
                visible={showInfo}
                onClose={() => setShowInfo(null)}
                current={showInfo}
            />
        </Pages>
    )
}
