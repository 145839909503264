import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Form, Input, Drawer, Button } from "antd";
import TEXTS from "../../../config/texts";
import Message from "../../../components/message";
import { REQUESTS } from "../../../api/requests";

import useResize from "../../../hooks/useResize"

export default function ActivationDrawer({
  visible,
  onClose,
  activationType,
  current,
  getSubresellers,
}) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });
  const windowWidth = useResize();

  const translation = useSelector((state) => state.globalState.translation);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const body = {
      reseller_id: +current.id,
      count: +values.count,
    };
    if (activationType == "Add Credit") {
      REQUESTS.SUBRESELLER_ACTIVATION.ADD(
        body,
        (data) => {
          getSubresellers();
          onClose();
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          setMessage({
            text: err,
            type: false,
          });
        }
      );
    } else {
      REQUESTS.SUBRESELLER_ACTIVATION.TAKE(
        body,
        (data) => {
          setLoading(false);
          onClose();
          getSubresellers();
        },
        (err) => {
          setLoading(false);
          setMessage({
            text: err,
            type: false,
          });
        }
      );
    }
  };

  const onSubmit = () => {
    setLoading(true);
    setMessage({
      text: "",
      type: true,
    });
    form.submit();
  };

  const onValuesChange = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  useEffect(() => {
    if (activationType === "Take Credit") {
      form.setFieldsValue({
        count: current?.total_activations,
      });
    } else {
      form.setFieldsValue({
        count: "",
      });
    }
  }, [activationType, current]);

  useEffect(() => {
    if (!visible) {
      setMessage({
        text: "",
        type: true,
      });
    }
  }, [visible]);


  return (
    <div>
      <Drawer
        title={
          activationType === "Add Credit"
            ? translation["Add Credit"] || TEXTS["Add Credit"]
            : translation["Take Credit"] || TEXTS["Take Credit"]
        }
        placement="right"
        onClose={onClose}
        open={visible}
        width={windowWidth > 600 ? 600 : "100%"}
      >
        <Form
          form={form}
          name="Add activations"
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >
          <div>
            <Form.Item
              label={translation["Count"] || TEXTS["Count"]}
              name="count"
              rules={[
                {
                  required: true,
                  message: translation["This field is required!"] || TEXTS["This field is required!"],
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Message message={message} />
            <Form.Item>
              <div className="activation-drawer-btn-container">
                <Button
                  type="primary"
                  loading={loading}
                  style={{
                    width: 200,
                  }}
                  onClick={onSubmit}
                >
                  {translation["Save"] || TEXTS["Save"]}
                </Button>
              </div>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
}
