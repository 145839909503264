import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { useSelector } from "react-redux";
import TEXTS from "../../../config/texts";
import SubResellerSubresellers from "./SubResellerSubresellers";
import SubResellerActivationHistory from "./SubResellerActivationHistory";
import useResize from "../../../hooks/useResize";

export default function InfoDrawer({ visible, onClose, current }) {

  const windowWidth = useResize();

  const translation = useSelector((state) => state.globalState.translation);

  return (
    <Drawer
      title={translation["Reseller info"] || TEXTS["Reseller info"]}
      placement="right"
      onClose={onClose}
      open={visible}
      width={windowWidth > 600 ? 600 : "100%"}
    >
      <div>
        {current && (
          <ul className="reseller-info-drawer-list">
            <li>
              {translation["Reseller name"] || TEXTS["Reseller name"]} :{" "}
              {current.name}
            </li>
            <li>
              {translation["Reseller email"] || TEXTS["Reseller email"]} :{" "}
              {current.email}
            </li>
            <li>
              {translation["Total Activations"] || TEXTS["Total Activations"]} :{" "}
              {current.total_activations}
            </li>
            <li>
              {translation["Activated devices"] || TEXTS["Activated devices"]} :{" "}
              {current.devices && current.devices.length}
            </li>
            <li>{translation["IP address"] || TEXTS["IP address"]} :{" "}{current.ip}</li>
            <li>
              {translation["Subresellers count"] || TEXTS["Subresellers count"]}{" "}
              : {current.subresellers_count}
            </li>
          </ul>
        )}
      </div>
      <SubResellerActivationHistory current={current} />
      <SubResellerSubresellers current={current} />
    </Drawer>
  )
}
