import { Tag } from "antd";

const Tags = () => {
    return (
        <Tag color="default">
            N/A
        </Tag>
    )
};

export default Tags;
