import { useState, useEffect } from "react";

import { useSelector } from 'react-redux';

import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';

import styles from "../index.module.scss";

const loadAmazonCheckoutScript = (onLoad, amazon_region = "US") => {
    const servers = {
        "US": 'https://static-na.payments-amazon.com/checkout.js',
        "EU": "https://static-eu.payments-amazon.com/checkout.js",
        "UK": "https://static-eu.payments-amazon.com/checkout.js",
        "JP": "https://static-fe.payments-amazon.com/checkout.js",
    }

    const script = document.createElement('script');
    script.src = servers[amazon_region];
    script.async = true;
    script.onload = onLoad;

    document.body.appendChild(script);

    return () => document.body.removeChild(script);
};

export default function AmazonButton({ selectedPackage }) {

    const { pymentSettings, profile } = useSelector(state => state.globalState);

    const [amazonConfig, setAmazonConfig] = useState(null);

    const fetchAmazonConfig = () => {
        const reseller_id = profile?.reseller?.id;
        const activation_package_id = selectedPackage?.id;

        if (!reseller_id || !activation_package_id) return;

        const query = {
            reseller_id,
            activation_package_id,
            type: "reseller_bay_activation"
        };

        setAmazonConfig(null);

        REQUESTS.GET_AMAZON_CONFIG(
            query,
            (response) => {
                setAmazonConfig(response);
            },
            () => setAmazonConfig(null)
        );
    };

    const renderAmazonPayButton = () => {
        if (!window.amazon || !window.amazon.Pay) {
            console.error('Amazon Pay SDK not loaded.');
            return;
        }

        if (document.getElementById('AmazonPayButton')) {
            document.getElementById('AmazonPayButton').innerHTML = '';
        }

        window.amazon.Pay.renderButton('#AmazonPayButton', {
            ...amazonConfig,
        });
    };

    useEffect(() => {
        if (selectedPackage && profile) fetchAmazonConfig();
    }, [selectedPackage, profile]);

    useEffect(() => {
        if (amazonConfig) {
            loadAmazonCheckoutScript(() => {
                renderAmazonPayButton()
            }, pymentSettings?.amazon_region);
        }
    }, [amazonConfig]);

    return <div className={styles['button-container']}>
        {!amazonConfig ?
            <Spin
                indicator={<LoadingOutlined
                    style={{
                        fontSize: 24,
                    }}
                    spin
                />}
            /> :
            <div id="AmazonPayButton" className={styles['amazon-pay']} />}
    </div>
}
