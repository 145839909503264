import React from 'react'

import style from "../index.module.scss";

export default function ParceladoPayment({ pay }) {

    return (
        <div className={style['parcelado-usa']} onClick={() => pay("parceladoUSA")} >Pagar com pix</div>
    )
}
