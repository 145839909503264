import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Drawer, Form, Input } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { REQUESTS } from "../../../api/requests";

import TEXTS from "../../../config/texts";

import Message from "../../../components/message";

import useResize from "../../../hooks/useResize";

import classes from "../style/referralLinksDrawer.module.scss";

// ReferralLinksDrawer component for adding and editing referral links. It is used in ReferralLinksPage component.
// It is a drawer that opens when you click on the "Add new link" button or on the "Edit" button in the table.
// It has a form with two fields: name and description. The form is validated and the data is sent to the server.
// The component is connected to the store to get the translation. The translation is used to display the text in the
// component. When the component is mounted, the current link data is set in the form fields. When the component is
// unmounted, the form fields are reset. When the form is submitted, the data is sent to the server. If the request is
// successful, the table is updated and the drawer is closed. If the request is unsuccessful, an error message is
// displayed. The component has a loading state. When the form is submitted, the loading state is set to true. When the
// request is successful or unsuccessful, the loading state is set to false. The component has a message state. When the
// form is submitted, the message state is set to an empty string. When the request is successful or unsuccessful, the
// message state is set to an error message. The component has a removeMessage function. When the form is submitted, the
// removeMessage function is called. When the request is successful or unsuccessful, the removeMessage function is  \
// called. The component has a removeSearchSorter function. When the component is mounted, the removeSearchSorter
// function is called. When the component is unmounted, the removeSearchSorter function is called.

const ReferralLinksDrawer = ({
  visible,
  onClose,
  current,
  getReferralLink,
  removeSearchSorter,
}) => {
  const windowWidth = useResize();

  const translation = useSelector((state) => state.globalState.translation);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      name: values.name,
      description: values.link,
    };

    function callback() {
      setLoading(false);
      getReferralLink();
      onClose();

      removeSearchSorter();
    }

    function errorCallback(error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }

    if (current) {
      body.id = current.id;

      REQUESTS.REFERRAL_LINKS.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.REFERRAL_LINKS.ADD(body, callback, errorCallback);
    }
  };

  const onValuesChange = () => {
    removeMessage();
  };

  const removeMessage = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  const onSubmit = () => {
    removeMessage();
    form.submit();
  };

  const onInputEnter = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  useEffect(() => {
    if (current) {
      form.setFields([
        { name: "name", value: current.name },
        { name: "link", value: current.description },
      ]);
    }
  }, [current]);

  useEffect(() => {
    removeMessage();

    if (!visible) {
      removeMessage();
      setLoading(false);
      form.resetFields();
    }
  }, [visible]);


  return (
    <Drawer
      width={windowWidth > 600 ? 600 : "100%"}
      title={
        current
          ? `${translation["Edit link"] || TEXTS["Edit link"]}`
          : `${translation["Add new link"] || TEXTS["Add new link"]}`
      }
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      <Form
        form={form}
        layout={"vertical"}
        name="activate device"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <div className={classes["form-input-block"]}>
          <Form.Item
            name="name"
            label={translation["Name"] || TEXTS["Name"]}
            rules={[
              {
                required: true,
                message:
                  translation["This field is required!"] ||
                  TEXTS["This field is required!"],
              },
            ]}
          >
            <Input
              onKeyDown={onInputEnter}
              placeholder={
                translation["This field is required!"] || TEXTS["This field is required!"]
              }
            />
          </Form.Item>
        </div>

        <Form.Item
          name="link"
          label={translation["Description"] || TEXTS["Description"]}
        >
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 4 }}
            onKeyDown={onInputEnter}
            placeholder={
              translation["Link description"] || TEXTS["Link description"]
            }
          />
        </Form.Item>

        <Message message={message} />

        <Form.Item className={classes["referral-button"]}>
          <Button
            loading={loading}
            type="primary"
            onClick={onSubmit}
            icon={<CheckOutlined />}
          >
            {translation["Save"] || TEXTS["Save"]}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ReferralLinksDrawer;
