import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import Card from "./Card";

import { REQUESTS } from "../../../api/requests";

import classes from "../style/referralStatistics.module.scss";
import TEXTS from "../../../config/texts";
// Referral statistics component. The component is used in the Dashboard page for to show the statistics of the referral. Get charts and cards data from the
// server.  The component uses the REQUESTS.DASHBOARD.CARDS function to get the cards data. The component uses the REQUESTS.DASHBOARD.CHARTS.REFERRAL function
// to get the charts data. The component uses the Card component to show the cards data. The component uses the Chart component to show the charts data.
// Translation is used to get the text from the translation file.

const ReferralStatistics = () => {
  const translation = useSelector((state) => state.globalState.translation);


  // The linksCards state is used to get the links cards data from the server.
  const [linksCards, setLinksCards] = useState({
    total_activated: 0,
    total_clicked: 0,
    total_links: 0,
  });

  // The withdrawHistoryCards state is used to get the withdraw history cards data from the server.
  const [withdrawHistoryCards, setWithdrawHistoryCards] = useState({
    earnings_this_month: 0,
    total_earnings: 0,
    total_withdraw: 0,
  });

  const [currentMonth, setCurrentMonth] = useState("");

  const month = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );

  const getCards = () => {
    function callback(data) {
      if (data.referral) {
        setLinksCards(data.referral.links);
        setWithdrawHistoryCards(data.referral.withdrawHistory);
      }
    }

    REQUESTS.DASHBOARD.CARDS(callback);
  };

  useEffect(() => {
    const date = new Date();
    setCurrentMonth(month[date.getMonth()]);
    getCards();
  }, []);

  return (
    <div>
      <h2 className={classes["dashboard-subtite"]}>{translation["Referall"] || TEXTS["Referall"]}</h2>
      <div className={classes["referral-statistics-cards"]}>
        <Card>
          <h1 className={classes["title"]}>{translation["Links"] || TEXTS["Links"]}</h1>
          <div className={classes["card-content"]}>
            <span>{translation["Total links"] || TEXTS["Total links"]}  : {linksCards.total_links}</span>
            <span>{translation["Links today"] || TEXTS["Links today"]} : {linksCards.links_today}</span>
          </div>
          <div className={classes["card-content"]}>
            <span>{translation["Total activated"] || TEXTS["Total activated"]} : {linksCards.total_activated}</span>
            <span>{translation["Activated today"] || TEXTS["Activated today"]} : {linksCards.activated_links_today}</span>
          </div>
        </Card>
        <Card>
          <h1 className={classes["title"]}>{translation["Earnings"] || TEXTS["Earnings"]}</h1>
          <span>{translation["Total"] || TEXTS["Total"]} : {withdrawHistoryCards.total_earnings}</span>
          <div className={classes["card-content"]}>
            <span>
              {currentMonth} : {withdrawHistoryCards.earnings_this_month}
            </span>
            <span>{translation["Today"] || TEXTS["Today"]} : {withdrawHistoryCards.earnings_this_day}</span>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ReferralStatistics;
