import React, { useEffect, useState } from 'react'

export default function useResize() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResie = (e) => {
            setWidth(window.innerWidth)
        };

        window.addEventListener("resize", handleResie);

        return () => window.removeEventListener("resize", handleResie);
    }, []);

    return width;
}
