import { useSelector } from "react-redux";
import IMAGES from "../../config/images";
import TEXTS from "../../config/texts";

/**
 * Patment Error Page Component is used to show error page when payment is failed. It is used in PaymentPage.jsx.
 * Redirected here from paypal or stripe.
 *
 * @returns
 */

export default function PaymentErrorPage({}) {
    const translation = useSelector((store) => store.globalState.translation);

    return (
        <div
            style={{
                width: "100%",
                height: "calc(100vh - 136px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img src={IMAGES.ERROR} />

            <h2
                style={{
                    marginTop: 30,
                    color: "white",
                    color: "#ef3434",
                    width: 500,
                    textAlign: "center",
                }}
            >
                {translation[
                    "Please check with your payment provider about the status of the account or contact our support"
                ] ||
                    TEXTS[
                        "Please check with your payment provider about the status of the account or contact our support"
                    ]}
            </h2>
        </div>
    );
}
