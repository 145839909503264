import { PROFILE, TRANSLATION, WITHDRAWAGAIN, APP_INFO, PAYMENT_SETTINGS, WIDGETS } from "./actionTypes";

export const setProfile = (user) => {
    return (dispatch) => {
        dispatch({
            type: PROFILE,
            payload: user,
        });
    };
};

export const setWidgets = (widgets) => {
    return (dispatch) => {
        dispatch({
            type: WIDGETS,
            payload: widgets,
        });
    };
};

export const setTranslation = (language) => {
    return (dispatch) => {
        dispatch({
            type: TRANSLATION,
            payload: language,
        });
    };
};

export const onGetWithdrawAgain = (again) => {
    return (dispatch) => {
        dispatch({
            type: WITHDRAWAGAIN,
            payload: again,
        });
    };
};

export const setAppInfo = (appInfo) => {
    return (dispatch) => {
        dispatch({
            type: APP_INFO,
            payload: appInfo,
        });
    };
};

export const setPaymentSettings = (pymentSettings) => {
    return (dispatch) => {
        dispatch({
            type: PAYMENT_SETTINGS,
            payload: pymentSettings,
        });
    };
}
