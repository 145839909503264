import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import Card from "./Card";

import { REQUESTS } from "../../../api/requests";
import style from "../style/resellerStatistics.module.scss";
import TEXTS from "../../../config/texts";

export default function ResellerStatistics() {
  const translation = useSelector((state) => state.globalState.translation);

  const [currentMonth, setCurrentMonth] = useState("");
  const [activated, setActivated] = useState({
    total: "",
    monthly: "",
    today: "",
  });
  const [credit, setCredit] = useState({ total: "", monthly: "", today: "" });

  const [subreseller, setSubreseller] = useState({
    total: "",
    monthly: "",
    today: "",
  });

  const month = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );

  const getActivationdata = () => {
    function callback(data) {
      if (data.reseller) {
        if (data.reseller.activated) {
          setActivated({
            total: data.reseller.activated.activation_count,
            monthly: data.reseller.activated.activations_this_month,
            today: data.reseller.activated.activations_today,
          });
        }

        if (data.reseller.activation) {
          setCredit({
            total: data.reseller.activation.spend_total,
            today: data.reseller.activation.spend_today,
            monthly: data.reseller.activation.spend_month,
          });
        }
      }
    }

    REQUESTS.DASHBOARD.CARDS(callback);
  };

  const getSubresellers = () => {
    function callback(data) {
      setSubreseller({
        total: data.count,
        monthly: data.mounth,
        today: data.day,
      });
    }

    REQUESTS.SUBRESELLERS_STATISTICS(callback);
  };

  useEffect(() => {
    const date = new Date();
    setCurrentMonth(month[date.getMonth()]);

    getSubresellers();
    getActivationdata();
  }, []);

  return (
    <div>
      <h1 className={style["dashboard-title"]}>{translation['Reseller'] || TEXTS['Reseller']}
      </h1>
      <div className={style["reseller-statistics-cards"]}>
        <Card>
          <h1 className={style["reseller-statistics-cards__content__title"]}>
            {translation['Activations'] || TEXTS['Activations']}
          </h1>
          <div className={style["reseller-statistics-cards__content"]}>
            <span>{translation['Total'] || TEXTS['Total']} : {activated.total} </span>
          </div>
          <div className={style["reseller-statistics-cards__content"]}>
            <span>
              {currentMonth} : {activated.monthly}
            </span>
            <span>{translation['Today'] || TEXTS['Today']} : {activated.today}</span>
          </div>
        </Card>
        <Card>
          <h1 className={style["reseller-statistics-cards__content__title"]}>
            {translation['Credit'] || TEXTS['Credit']}
          </h1>
          <div className={style["reseller-statistics-cards__content"]}>
            <span>{translation['Total'] || TEXTS['Total']} : {credit.total} </span>
          </div>
          <div className={style["reseller-statistics-cards__content"]}>
            <span>
              {currentMonth} : {credit.monthly}
            </span>
            <span>{translation['Today'] || TEXTS['Today']} : {credit.today}</span>
          </div>
        </Card>
        <Card>
          <h1 className={style["reseller-statistics-cards__content__title"]}>
            {translation['Subreseller'] || TEXTS['Subreseller']}
          </h1>
          <div className={style["reseller-statistics-cards__content"]}>
            <span>{translation['Total'] || TEXTS['Total']} : {subreseller.total}</span>
          </div>
          <div className={style["reseller-statistics-cards__content"]}>
            <span>
              {currentMonth} : {subreseller.monthly}
            </span>
            <span>{translation['Today'] || TEXTS['Today']} : {subreseller.today}</span>
          </div>
        </Card>
      </div>
    </div>
  );
}
