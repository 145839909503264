const CONSTANTS = {

    NOT_AVAILABLE: "N/A",

    JWT: "JWT",

    API_HOST: window.API_URL || "http://api.x-players.com/",

    REACT_APP_WEB_DOMAIN: window.WEB_DOMAIN || "http://web.x-players.com/",

    APP_NAME: window.APP_NAME || "XPLAYER WEB",

    APP_COLOR: "black",

};

export default CONSTANTS;
