import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { Table } from "antd";

import { REQUESTS } from "../../api/requests";

import ICONS from "../../config/icons";
import TEXTS from "../../config/texts";
import { parseFullDate } from "../../config/formats";
import { getColumnDateProps, getColumnSearchProps, getQueryDate } from "../../config/config";

import MyIcon from "../../components/IconsAntSvg";
import Pages from "../../components/Pages";
import Tags from "../../components/Tags";
import BuyMoreActivationsDrawer from "../../layout/components/BuyMoreActivationsDrawer";
import InfoDrawer from "../subresellers/components/InfoDrawer";

/**
 * Activations history page. We have a table that shows the activations history. We can search, sort, and filter the table.
 * From here we can buy more activations. BuyMoreActivations drawer is rendered when we click on the buy more activations
 * button.
 * @returns
 */

export default function ActivationsHistory() {
    // appInfo for the app title and favicon. translation for the texts. navigate for the navigation. searchParams for
    // the search params.
    const appInfo = useSelector((state) => state.globalState.appInfo);

    // translation for the texts.
    const translation = useSelector((state) => state.globalState.translation);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // visibleBuyMoreActivationsDrawer for the buy more activations drawer. Drawers are rendered when we click on the
    // buy more activations button.
    const [visibleBuyMoreActivationsDrawer, setVisibleBuyMoreActivationsDrawer] = useState(false);

    // activationsHistory for the activations history. total for the total count of the activations history.
    const [activationsHistory, setActivationsHistory] = useState([]);

    // total for the total count of the activations history.
    const [total, setTotal] = useState(0);

    // limit for the limit of the activations history.
    const [limit, setLimit] = useState(searchParams.get("limit") || 10);

    //laoading for showing the loading icon in the table.
    const [loading, setLoading] = useState(false);

    // currentPage for the current page of the activations history. It is used for the pagination.
    const [currentPage, setCurrentPage] = useState(1);

    // sort for the sort of the activations history. It is used for the sorting. It is an array. The first element is the key and the
    // second element is the order.
    const [sort, setSort] = useState(["id", "DESC"]);

    // date for the date of the activations history. It is used for the filtering.
    const [date, setDate] = useState("");

    const [showInfo, setShowInfo] = useState(null);

    // search for the search of the activations history. It is used for the searching. It is an object. The keys are the columns and the
    // values are the search values.
    const [search, setSearch] = useState(searchParams.get("search") || {});

    const searchInput = useRef(null);

    const paymentSettings = useSelector((state) => state.globalState.pymentSettings);

    const columns = [
        {
            title: "#",
            width: 60,
            dataIndex: `id`,
            align: "center",
            key: "index",
            render: (text, record, index) => {
                return limit * (currentPage - 1) + index + 1;
            },
        },
        {
            title: `${translation["Method"] || TEXTS["Method"]}`,
            dataIndex: "method",
            key: "method",
            align: "center",
            ...getColumnSearchProps(searchInput),
            render: (text, record, index) => {
                const method = text === "to sub reseller" || text === "back to balance" || text === "activation added" || text === "activation taken"
                return <span className={method ? "method-link" : ""}>{text}</span>
            }
        },
        {
            title: `${translation["Transaction id"] || TEXTS["Transaction id"]}`,
            dataIndex: "transaction_id",
            align: "center",
            key: "transaction_id",
            render: (record, text, index) => {
                return record || <Tags />;
            },
            ...getColumnSearchProps(searchInput),
        },
        {
            title: `${translation["Count"] || TEXTS["Count"]}`,
            dataIndex: "count",
            align: "center",
            key: "count",
            sorter: true,
        },
        {
            title: `${translation["Status"] || TEXTS["Status"]}`,
            dataIndex: "status",
            align: "center",
            key: "status",
            sorter: true,
            render: (record, text, index) => {
                if (record) {
                    return <MyIcon children={ICONS.CHECK} />;
                } else {
                    return <MyIcon children={ICONS.TIMES} />;
                }
            },
        },
        {
            title: `${translation["Price"] || TEXTS["Price"]}`,
            dataIndex: `price`,
            align: "center",
            key: "price",
            render: (record, text, index) => {
                return `${record} ${paymentSettings?.currency}`;
            },
            sorter: true,
        },
        {
            title: `${translation["Date"] || TEXTS["Date"]}`,
            dataIndex: "createdAt",
            align: "center",
            key: "createdAt",
            sorter: true,
            render: (record, text, index) => {
                return parseFullDate(record);
            },
            ...getColumnDateProps(setDate),
        },
    ];

    // handleTableChange is used for the table change. It is used for the pagination, sorting, and filtering.
    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }

        setSearch(filters);

        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    // getActivationsHistory is used for getting the activations history.
    const getActivationsHistory = () => {
        setLoading(true);

        // query is the query for the activations history. It is used for the pagination, sorting, searching, and filtering.
        const query = {
            limit,
            search: {},
            pagination: 1,
            page: currentPage,
            sort: JSON.stringify(sort),
        };

        if (search.transaction_id) {
            query.search["transaction_id"] = search.transaction_id[0];
        }
        if (search.method) {
            query.search["method"] = search.method[0];
        }

        if (query.search) {
            query.search = JSON.stringify(query.search);
        }

        const queryDate = getQueryDate(date);

        if (queryDate) query.between = queryDate;

        function callback(data) {
            setLoading(false);
            setTotal(data.count);
            setLimit(data.limit);
            setCurrentPage(data.currentPage);
            setActivationsHistory(data.rows);

            if (data.rows.length === 0 && currentPage !== 1) {
                setCurrentPage((current) => current - 1);
            } else {
                setCurrentPage(data.currentPage);
            }
        }

        function errorCallback() {
            setLoading(false);
        }

        navigate({
            search: `${createSearchParams(query)}`,
        });

        REQUESTS.ACTIVATIONS_HISTORY.GET(query, callback, errorCallback);
    };

    const getResellerInfo = (obj, type) => {
        if (!obj[type]) return;

        const query = {
            filter: JSON.stringify({
                id: obj[type]
            })
        }

        const callback = (response) => setShowInfo(response.rows?.[0] || {});

        REQUESTS.SUBRESELLERS.GET(query, callback)
    }

    const tableRowOnClick = (record) => {
        try {
            if (record.method === "to sub reseller" || record.method === "back to balance" || record.method === "activation added" || record.method === "activation taken") {
                let paymentInfo;

                let type = record?.method === "to sub reseller" || record?.method === "back to balance" ? "sub_reseller_id" : record?.method === "activation added" || record?.method === "activation taken" ? "reseller_id" : null;

                paymentInfo = JSON.parse(record.payment_info);

                getResellerInfo(paymentInfo, type)
            }
        } catch (error) { }
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            getActivationsHistory();
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [sort, date, limit, currentPage, search]);

    return (
        <Pages
            title={translation["Credits history"] || TEXTS["Credits history"]}
            onClick={() => setVisibleBuyMoreActivationsDrawer(true)}
            actions={translation["Buy more Credits"] || TEXTS["Buy more Credits"]}
        >
            <Table
                rowKey="id"
                bordered
                loading={loading}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                columns={columns}
                dataSource={activationsHistory}
                scroll={{
                    x: "max-content",
                }}
                size="small"
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => tableRowOnClick(record)
                    }
                }}
            />

            <BuyMoreActivationsDrawer
                getActivationsHistory={getActivationsHistory}
                visible={visibleBuyMoreActivationsDrawer}
                onClose={() => setVisibleBuyMoreActivationsDrawer(false)}
            />
            <InfoDrawer
                visible={showInfo}
                onClose={() => setShowInfo(null)}
                current={showInfo}
            />
        </Pages>
    );
}
