import React from 'react'
import { useSelector } from 'react-redux'

import classes from "../styles/buyMoreActivationsDrawer.module.scss";

export default function PixiCheckoutButton({ pay = () => { } }) {
    const paymentSettings = useSelector(state => state.globalState.pymentSettings);

    return paymentSettings.dlocal_enabled && (
        <div
            onClick={() => pay("dlocal")}
            className={classes["pixi-payment-button"]}
        >
            Pixi pay
        </div>
    )
}
