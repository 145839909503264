import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { Layout } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MenuOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import { actions } from "../../store/index";

import { REQUESTS } from "../../api/requests";

import Profile from "./Profile";
import WalletBlock from "./WalletBlock";
import LanguageBlock from "./LanguageBlock";
import TEXTS from "../../config/texts";
import classes from "../styles/header.module.scss";
import useResize from "../../hooks/useResize";

// The Header component is used to display a header to the user. From props we get the collapsed state of the
// sidebar and the function that will be called when the sidebar is collapsed. The Header component also
// contains the Profile, WalletBlock and LanguageBlock components. PaymentSettings is also called here.
// PaymentSettings is called here because it is used in the WalletBlock component.

function Headers({ collapsed, setCollapsed, setOpenMenuDrawer }) {
  const windowWidth = useResize();
  const [credit, setCredit] = useState('');

  const dispatch = useDispatch();
  const translation = useSelector((state) => state.globalState.translation);
  const profile = useSelector((state) => state.globalState.profile);

  const { setProfile } = bindActionCreators(actions, dispatch);
  const { setPaymentSettings } = bindActionCreators(actions, dispatch);

  const getProfile = () => {
    function callback(data) {
      setProfile(data);
      setCredit(data.reseller?.total_activations)
    }

    REQUESTS.PROFILE.GET(callback);
  };

  const getPaymentSettings = () => {
    REQUESTS.PAYMENT_SETTINGS((data) => {
      setPaymentSettings(data);
    });
  };

  useEffect(() => {
    getProfile();
    getPaymentSettings();
  }, []);

  return (
    <Layout.Header className={classes["header"]}>
      {windowWidth > 800 ? (
        <div>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </div>
      ) : (
        <MenuOutlined onClick={() => setOpenMenuDrawer(true)} />
      )}

      <div className={classes["header-item-block"]}>
        <LanguageBlock />
        <WalletBlock />

        <div className={classes["line-block"]}> </div>
        <Profile getProfile={getProfile} />

        <div className={classes["line-block"]}> </div>

        <div className={classes["credit-refresh-btn-block"]}>
          {profile && profile.reseller && (
            <strong>
              {`${translation["Credit"] || TEXTS["Credit"]} - ${credit || 0
                }`}
            </strong>
          )}
          <SyncOutlined
            onClick={() => {
              setCredit("")
              setTimeout(() => {
                getProfile()
              }, 100);
            }}
            className={classes["refresh-btn"]}
          />
        </div>
      </div>
    </Layout.Header>
  );
}

export default Headers;
