import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { notification } from "antd";
import { REQUESTS } from "../../api/requests";

import styles from "../index.module.scss";

export default function CMIButton({ selectedPackage }) {
    const [loading, setLoading] = useState(false)
    const cmiPaymentForm = useRef();
    const profile = useSelector((state) => state.globalState.profile);
    const paymentSettings = useSelector(state => state.globalState.pymentSettings);

    const payWithCMI = () => {
        setLoading(true)
        let reseller = profile.reseller;

        if (!reseller) return;

        const body = {
            name: reseller.name,
            email: reseller.email,
            activation_package_id: selectedPackage?.id,
            type: "reseller_bay_activation",
            reseller_id: reseller.id,
        };

        const callback = (data) => {
            for (let key in data) {
                let input = document.createElement("input");
                input.type = "hidden";
                input.value = data[key];
                input.name = key;
                cmiPaymentForm.current.appendChild(input);
            }

            cmiPaymentForm.current.submit();

            setTimeout(() => {
                setLoading(false)
            }, 3000);
        }

        const errorCallback = (err) => {
            notification.error({
                message: "Error",
                description: err.message,
            });

            setTimeout(() => {
                setLoading(false)
            }, 3000);
        }

        REQUESTS.CMI_PAYMENT(body, callback, errorCallback)
    };

    return paymentSettings?.cmi_enabled && (
        <div>
            <div
                className={styles['cmi-button']}
                onClick={() => {
                    if (loading) return;
                    payWithCMI()
                }}
                style={{ cursor: loading ? "not-allowed" : 'pointer' }}
            >
                PAY NOW
            </div>

            <form
                ref={cmiPaymentForm}
                method={"post"}
                action={paymentSettings?.geteway_url}
            ></form>
        </div>
    )
}
