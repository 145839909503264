import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Button, Drawer, Popover, Table } from "antd";

import ICONS from "../../../config/icons";
import { confirm } from "../../../config/confirm";
import { parseFullDate } from "../../../config/formats";

import { REQUESTS } from "../../../api/requests";

import TableButtons from "../../../components/TableButtons";

import PlaylistDrawer from "./PlaylistDrawer";
import TEXTS from "../../../config/texts";
import useResize from "../../../hooks/useResize";

import classes from "../index.module.scss";

export default function PlaylistsDrawer({ visible, onClose, deviceId }) {
  const translation = useSelector((state) => state.globalState.translation);

  const [visiblePlaylistDrawer, setVisiblePlaylistDrawer] = useState(false);

  const [playlist, setPlaylist] = useState([]);
  const [playlistItem, setPlaylistItem] = useState(null);
  const windowWidth = useResize();

  const deletePlaylist = (id) => {
    function callback(response) {
      getPlaylist();
    }
    function errorCallback(error) {
    }
    REQUESTS.PLAYLIST.DELETE({ id, deviceId }, callback, errorCallback);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setVisiblePlaylistDrawer(true);
        setPlaylistItem(item);
        break;
      case "delete":
        confirm(translation["Do you want to delete?"] || TEXTS["Do you want to delete?"],
          () => deletePlaylist(item.id),
          translation["Yes"] || TEXTS["Yes"],
          translation["No"] || TEXTS["No"],
        );
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      title: "#",
      width: 60,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return index + 1;
      },
    },
    {
      title: translation["Playlist name"] || TEXTS["Playlist name"],
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, record, index) => {
        return record?.name;
      },
    },
    {
      title: translation["Playlist url"] || TEXTS["Playlist url"],
      dataIndex: "url",
      key: "url",
      align: "center",
      width: "150px",
      render: (text, record, index) => {
        return (
          <Popover content={<div>{record?.url}</div>}>
            <span className={classes["playlist-url"]}>{record?.url}</span>
          </Popover>
        );
      },
    },
    {
      title: translation["Date"] || TEXTS["Date"],
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) => {
        return parseFullDate(record?.createdAt);
      },
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: "2%",
      render: (text, record, index) => {
        return (
          <TableButtons
            buttons={[
              {
                text: translation["Edit"] || TEXTS["Edit"],
                key: "edit",
                icon: ICONS.PEN,
              },
              {
                text: translation["Delete"] || TEXTS["Delete"],
                key: "delete",
                icon: ICONS.TRASH,
              },
            ]}
            handleMenuClick={(e) => handleMenuClick(e, record)}
          />
        );
      },
    },
  ];

  const getPlaylist = () => {
    function callback(data) {
      setPlaylist(data);
    }
    function errorCallback() { }

    REQUESTS.PLAYLIST.GET({ deviceId }, callback, errorCallback);
  };

  useEffect(() => {
    if (!visible) {
      setPlaylist([]);
    } else {
      getPlaylist();
    }
  }, [visible, deviceId]);

  return (
    <Drawer
      width={windowWidth > 600 ? 600 : "100%"}
      placement="right"
      title={translation["Playlist"] || TEXTS["Playlist"]}
      onClose={onClose}
      open={visible}
    >
      <Button
        type="primary"
        size={"large"}
        style={{ float: "right", marginBottom: "20px" }}
        onClick={() => {
          setVisiblePlaylistDrawer(true);
          setPlaylistItem(null);
        }}
      >
        {translation["Add playlist"] || TEXTS["Add playlist"]}
      </Button>

      <Table
        bordered
        rowKey="id"
        pagination={false}
        columns={columns}
        dataSource={playlist}
        scroll={{
          x: "max-content",
        }}
        size="small"
      />

      <PlaylistDrawer
        visible={visiblePlaylistDrawer}
        onClose={() => setVisiblePlaylistDrawer(false)}
        current={playlistItem}
        deviceId={deviceId}
        getPlaylist={getPlaylist}
      />
    </Drawer>
  );
}
