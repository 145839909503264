import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Input } from "antd";
import { UserOutlined, SendOutlined } from "@ant-design/icons";

import { REQUESTS } from "../../../api/requests";

import TEXTS from "../../../config/texts";

import Message from "../../../components/message";

import classes from "../styles/forgotPasswordForm.module.scss";

// This component is used to send a request to the server to reset the password. The user enters his email and the server sends a link to reset
// the password to the email address. The component is used in the Login page. For state we use the Ant Design Form component. Form have a his state
// and we can use it to get the values of the form fields. Loading state is used to show the loading animation when the request is sent to the server.
// Message state is used to show the message to the user. The message can be an error or a success message. The message is shown in the Message component.

const ForgotPasswordForm = ({ active }) => {
    const translation = useSelector((state) => state.globalState.translation);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        text: "",
        type: true,
    });

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setLoading(true);

        const body = {
            email: values.username,
        };

        function callback(data) {
            setLoading(false);

            setMessage({
                text: data,
                type: true,
            });
        }

        function errorCallback(error) {
            setLoading(false);
            setMessage({
                text: error,
                type: false,
            });
        }

        function networkError(error) {
            setLoading(false);
            setMessage({
                text: error,
                type: false,
            });
        }

        REQUESTS.FORGOT_PASSWORD(body, callback, errorCallback, networkError);
    };

    const onFinishFailed = (error) => {
        setLoading(false);

        if (typeof error === "string") {
            setMessage({
                text: error,
                type: false,
            });
        }
    };

    const onValueChange = () => {
        removeMessage();
    };

    const removeMessage = () => {
        setMessage({
            text: "",
            type: true,
        });
    };

    const onSubmit = () => {
        removeMessage();
        form.submit();
    };

    return (
        <Form
            form={form}
            name="forgot-form"
            className="login-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onValueChange}
        >
            <div className={classes["form-input-block"]}>
                <Form.Item
                    name="username"
                    rules={[
                        {
                            type: "email",
                            message:translation["The input is not valid Email!"] ||
                            TEXTS["The input is not valid Email!"] ,
                        },
                        {
                            required: true,
                            message:
                                translation["This field is required!"] ||
                                TEXTS["This field is required!"],
                        },
                    ]}
                >
                    <Input
                        size="large"
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder={translation["Email"] || TEXTS["Email"]}
                    />
                </Form.Item>
            </div>

            <Form.Item className={classes["form-items"]}>
                <a onClick={active}  href="">
                    {translation["Back to login"] || TEXTS["Back to login"]}
                </a>
            </Form.Item>

            <Message message={message} />

            <Form.Item className={classes["form-items"]}>
                <Button
                    loading={loading}
                    size={"large"}
                    type="primary"
                    className="login-form-button"
                    icon={<SendOutlined />}
                    onClick={onSubmit}
                >
                    {translation["Send email"] || TEXTS["Send email"]}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ForgotPasswordForm;
